import { Heading } from 'deprecated-enkel';
import styled from 'styled-components';
import { transparentize, margin } from 'polished';

import { TotemLabel } from 'styles';

import { IMAGE_LOGIN_BACKGROUND } from 'data/images';

import { buildImgUrl } from 'utils/image/buildImgUrl';
import { createOptimizedImageUri } from 'utils/image/createOptimizedImageUri';

export const SignupStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
`;

export const SignupForm = styled.form`
    display: flex;
    flex-direction: column;
    label {
        min-width: 300px;
    }
`;

const SignupHalfStyle = styled.div`
    flex-shrink: 0;
    height: 50%;

    @media only screen and (max-width: 600px) {
        height: 100%;
    }
`;

export const SignupHalfStyleOne = styled(SignupHalfStyle)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => props.position || 'center'};
    background: ${(props) => props.theme.colors.black};
    user-select: none;
`;

export const SignupHalfStyleTwo = styled(SignupHalfStyle)`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    background: ${(props) =>
        props.backgroundUrl
            ? `url(${createOptimizedImageUri({ src: buildImgUrl(IMAGE_LOGIN_BACKGROUND) })}) no-repeat center / cover`
            : props.backgroundColor};
    overflow: hidden;

    > img {
        margin-top: -1px;
    }

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const SignupWrapperStyle = styled.div`
    height: 100%;
    background: ${(props) => props.$transparentize && transparentize(0.5, props.theme.colors.black)};
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: ${(props) => props.position || 'center'};
`;

export const SignupFormStyle = styled.form`
    width: 90%;
    max-width: 450px;
`;

export const SignupFormStyleTwo = styled.form`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 90%;
    max-width: 650px;

    label {
        width: ${(props) => props.inputWidth || '100%'};
    }
`;

export const SignupTitleStyle = styled(Heading.One.Style)`
    width: ${(props) => (props.width ? props.width : '100%')};
    color: ${(props) => (props.color ? props.color : 'white')};
    max-width: 450px;
    padding: 40px 10px;
    line-height: 1.3;
    ${(props) =>
        props.$noMargin
            ? 'margin: 0'
            : margin(props.theme.variables.margin * 2.5, 0, props.theme.variables.margin * 0.75)};
    border-bottom: none;
    text-align: center;

    @media not screen, (min-width: 600px) {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '2em')};
    }

    @media only screen and (max-width: 600px) {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '2em')};
    }

    b {
        color: ${(props) => props.theme.colors.yellow};
    }
`;

export const SignupLinkStyle = styled.a`
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const SignupOneRow = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: center;

    ${TotemLabel} {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    @media only screen and (max-width: 765px) {
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        ${TotemLabel} {
            margin-left: 0;
            margin-right: 0;
        }
    }
`;

export const SignUpTotemLabel = styled(TotemLabel)`
    @media not screen, (min-width: 600px) {
        margin: 5px;
    }

    @media only screen and (max-width: 600px) {
        margin: 0;
    }
`;

export const SmallTextStyle = styled.p`
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
`;

const typeMapper = {
    Installation: 'Installation',
    Repair: 'Réparation',
    Removal: 'Retrait',
    Unknown: 'Installation',
};

const equipmentMapper = {
    CoffeeMachine: "d'une machine à café",
    WaterFountain: "d'une fontaine à eau",
    StoreFurniture: "d'un meuble du magasin",
    FreefoodFurniture: "d'un meuble du magasin",
    Unknown: "d'un équipement",
};

export const getInterventionText = (type, equipment) => `${typeMapper[type]} ${equipmentMapper[equipment]}`;

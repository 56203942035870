import gql from 'graphql-tag';

export const ASANA_TASKS_BY_ORGANIZATION_ID = gql`
    query AsanaTasksByOrganizationId($organizationId: String!) {
        asanaTasksByOrganizationId(organizationId: $organizationId) {
            organizationName
            tasks {
                _id
                name
                notes
                contractor
                type
                equipmentType
                urgencyLevel
                organizationId
                organizationName
                contactEmail
                stage
                createdAt
                updatedAt
                dueOn
                comment
            }
        }
    }
`;

import React from 'react';
import { useParams } from 'react-router-dom';
import { ASANA_TASKS_BY_ORGANIZATION_ID } from 'data/queries';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Loading } from 'components/Loading';
import { Logo } from 'components/Logo';
import { getInterventionText } from './utils';

export const InterventionFollowUp = () => {
    const { organizationId } = useParams();

    const { loading, data } = useQuery(ASANA_TASKS_BY_ORGANIZATION_ID, {
        variables: {
            organizationId,
        },
        skip: !organizationId,
    });

    const tasks = [...(data?.asanaTasksByOrganizationId?.tasks ?? [])].sort((a, b) => b.createdAt - a.createdAt);
    const organizationName = data?.asanaTasksByOrganizationId?.organizationName;

    const upcomingTasks = tasks?.filter((task) => ['New', 'InProgress'].includes(task.stage));
    const completedTasks = tasks?.filter((task) => task.stage === 'Completed');

    if (loading) {
        return <Loading />;
    }

    const getContent = (input, status) => {
        if (!input?.length) {
            return <Message>Aucune intervention à afficher</Message>;
        }

        return (
            <CardGrid>
                {input.map((task) => {
                    return (
                        <TaskCard key={task._id}>
                            <TitleAndStatusContainer>
                                <CardTitle>{task.name || getInterventionText(task.type, task.equipmentType)}</CardTitle>
                                <Status status={status}>{status}</Status>
                            </TitleAndStatusContainer>
                            <CardDetail>
                                <strong>Nature de l'intervention:</strong>{' '}
                                {getInterventionText(task.type, task.equipmentType)}
                            </CardDetail>
                            {task.dueOn && (
                                <CardDetail>
                                    <strong>Date de l'intervention:</strong> {new Date(task.dueOn).toLocaleDateString()}
                                </CardDetail>
                            )}
                            {task.comment && (
                                <CardDetail>
                                    <strong>Commentaire:</strong> {task.comment}
                                </CardDetail>
                            )}
                        </TaskCard>
                    );
                })}
            </CardGrid>
        );
    };

    return (
        <Container>
            <Logo dark />
            <Text>
                {`Cette page liste toutes les interventions sur les équipements Totem à venir et terminées ${organizationName ? `pour le compte ${organizationName}` : 'pour votre compte'}.`}
            </Text>
            <Section>
                <Title>Interventions à venir</Title>
                {getContent(upcomingTasks, 'À venir')}
            </Section>
            <Section>
                <Title>Interventions terminées</Title>
                {getContent(completedTasks, 'Terminée')}
            </Section>
        </Container>
    );
};

const Text = styled.p`
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0;
`;

const Container = styled.div`
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    width: 100%;
`;

const Title = styled.h1`
    font-size: 2rem;
`;

const Message = styled.div`
    font-size: 1.2rem;
    color: #666;
`;

const CardGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const TaskCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
`;
const TitleAndStatusContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: start;
`;

const CardTitle = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
    flex: 1 1;
`;

const Status = styled.div`
    background-color: ${(props) =>
        props.status === 'Terminée' ? props.theme.colors.green : props.theme.colors.lightGrey};
    color: #000;
    padding: 2px 4px;
    border-radius: 4px;
    flex: 0 1;
    white-space: nowrap;
`;

const CardDetail = styled.p`
    font-size: 1rem;
    margin: 0;
    color: #666;
`;

import gql from 'graphql-tag';
import { ORDER_FRAGMENT } from 'data/fragments/order.fragments';
import { TOTEM_FRAGMENT } from 'data/fragments/totem.fragments';

export const ADD_PRODUCT = gql`
    mutation ADD_PRODUCT($orderId: String!, $productId: String!) {
        addProduct(orderId: $orderId, productId: $productId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const REMOVE_PRODUCT = gql`
    mutation REMOVE_PRODUCT($orderId: String!, $productId: String!) {
        removeProduct(orderId: $orderId, productId: $productId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const LESS_PRODUCT = gql`
    mutation LESS_PRODUCT($orderId: String!, $productId: String!) {
        lessProduct(orderId: $orderId, productId: $productId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const MORE_PRODUCT = gql`
    mutation MORE_PRODUCT($orderId: String!, $productId: String!) {
        moreProduct(orderId: $orderId, productId: $productId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const SET_QUANTITY = gql`
    mutation SET_QUANTITY($orderId: String!, $productId: String!, $quantity: Float!) {
        setQuantity(orderId: $orderId, productId: $productId, quantity: $quantity) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const REACTIVATE_ORDER = gql`
    mutation REACTIVATE_ORDER($orderId: String!) {
        reactivateOrder(orderId: $orderId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const VALIDATE_RATING = gql`
    mutation VALIDATE_RATING(
        $orderId: String!
        $deliveryDate: String!
        $totemId: String!
        $appreciation: Int!
        $feedback: String!
        $badServices: [String]!
    ) {
        validateRating(
            orderId: $orderId
            deliveryDate: $deliveryDate
            totemId: $totemId
            appreciation: $appreciation
            feedback: $feedback
            badServices: $badServices
        ) {
            _id
        }
    }
`;

export const CHECKOUT = gql`
    mutation CHECKOUT($data: CheckoutInput!) {
        checkout(data: $data) {
            order {
                ...OrderFragment
            }
            organization {
                _id
                state
            }
            totem {
                ...TotemFragment
            }
        }
    }
    ${ORDER_FRAGMENT}
    ${TOTEM_FRAGMENT}
`;

export const VALIDATE = gql`
    mutation VALIDATE($orderId: ID!) {
        validate(orderId: $orderId) {
            order {
                ...OrderFragment
            }
            organization {
                _id
                state
            }
        }
    }
    ${ORDER_FRAGMENT}
`;

export const DISCARD = gql`
    mutation DISCARD($orderId: String!) {
        discard(orderId: $orderId) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const CREATE_NEW_RECURRING_ORDER = gql`
    mutation CREATE_NEW_RECURRING_ORDER(
        $totemId: ID!
        $deliveryDate: String!
        $deliveryTimeWindow: DeliveryTimeWindowInput!
        $frequency: OrderFrequency!
    ) {
        createNewRecurringOrder(
            totemId: $totemId
            deliveryDate: $deliveryDate
            deliveryTimeWindow: $deliveryTimeWindow
            frequency: $frequency
        ) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const DUPLICATE_ORDER = gql`
    mutation DUPLICATE_ORDER(
        $orderToDuplicateId: ID!
        $destinationTotemId: ID!
        $deliveryDate: String!
        $deliveryTimeWindow: DeliveryTimeWindowInput!
        $isOrderPunctual: Boolean!
    ) {
        duplicateOrder(
            orderToDuplicateId: $orderToDuplicateId
            destinationTotemId: $destinationTotemId
            deliveryDate: $deliveryDate
            deliveryTimeWindow: $deliveryTimeWindow
            isOrderPunctual: $isOrderPunctual
        ) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const DUPLICATE_ORDER_AND_CREATE_TOTEM = gql`
    mutation DUPLICATE_ORDER_AND_CREATE_TOTEM(
        $orderToDuplicateId: ID!
        $deliveryInfo: DeliveryInfoInput!
        $deliveryDate: String!
        $deliveryTimeWindow: DeliveryTimeWindowInput!
        $isOrderPunctual: Boolean!
    ) {
        duplicateOrderAndCreateTotem(
            orderToDuplicateId: $orderToDuplicateId
            deliveryInfo: $deliveryInfo
            deliveryDate: $deliveryDate
            deliveryTimeWindow: $deliveryTimeWindow
            isOrderPunctual: $isOrderPunctual
        ) {
            totem {
                ...TotemFragment
            }
            order {
                ...OrderFragment
            }
            user {
                _id
                roles
            }
        }
    }
    ${ORDER_FRAGMENT}
    ${TOTEM_FRAGMENT}
`;

export const CREATE_PUNCTUAL_ORDER = gql`
    mutation CREATE_PUNCTUAL_ORDER(
        $totemId: ID!
        $deliveryDate: String!
        $deliveryTimeWindow: DeliveryTimeWindowInput!
    ) {
        createPunctualOrder(totemId: $totemId, deliveryDate: $deliveryDate, deliveryTimeWindow: $deliveryTimeWindow) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const DELETE_ORDER = gql`
    mutation DELETE_ORDER($orderId: String!) {
        deleteOrder(orderId: $orderId)
    }
`;

export const UPDATE_ORDER_DELIVERY_DATE_TIME = gql`
    mutation UPDATE_ORDER_DELIVERY_DATE_TIME(
        $orderId: ID!
        $dateDelivery: String
        $deliveryTimeWindow: DeliveryTimeWindowInput
    ) {
        updateOrderDeliveryDateTime(
            orderId: $orderId
            dateDelivery: $dateDelivery
            deliveryTimeWindow: $deliveryTimeWindow
        ) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER_WITH_STOCK_QUANTITIES = gql`
    mutation UPDATE_ORDER_WITH_STOCK_QUANTITIES($orderId: String!, $productsStockToUpdate: [ProductsStockInput]!) {
        updateOrderWithStockQuantities(orderId: $orderId, productsStockToUpdate: $productsStockToUpdate) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const PAUSE_ORDER = gql`
    mutation PAUSE_ORDER($orderId: ID!, $returnDate: GraphQLDate!, $reason: String!) {
        pauseOrder(orderId: $orderId, returnDate: $returnDate, reason: $reason) {
            order {
                ...OrderFragment
            }
            success
            error
        }
    }
    ${ORDER_FRAGMENT}
`;

export const UPDATE_HAS_ONSITE_SETUP = gql`
    mutation UPDATE_HAS_ONSITE_SETUP($orderId: ID!, $hasOnsiteSetup: Boolean!) {
        updateHasOnsiteSetup(orderId: $orderId, hasOnsiteSetup: $hasOnsiteSetup) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER_ONSITE_SETUP_INSTRUCTIONS = gql`
    mutation UPDATE_ORDER_ONSITE_SETUP_INSTRUCTIONS(
        $makeDefault: Boolean!
        $orderId: ID!
        $onsiteSetupInstructions: String!
    ) {
        updateOrderOnsiteSetupInstructions(
            makeDefault: $makeDefault
            orderId: $orderId
            onsiteSetupInstructions: $onsiteSetupInstructions
        ) {
            order {
                _id
                onsiteSetupInstructions
            }
            totem {
                _id
                consigneDelivery
            }
        }
    }
`;

import gql from 'graphql-tag';

export const REQUESTED_ORDER_PRODUCT_FRAGMENT = gql`
    fragment RequestedOrderProductFragment on RequestedOrderProduct {
        _id
        name
        price
        unitPrice
        categoryId
        orderId
        portion
        quantity
        displayKg
        imageUrl
        tva
        weightWithoutPackaging
        quantityRequested
        quantityPicked
    }
`;

export const ORDER_PRODUCT_FRAGMENT = gql`
    fragment OrderProductFragment on OrderProduct {
        _id
        flow
        name
        price
        unitPrice
        categoryId
        orderId
        portion
        quantity
        displayKg
        imageUrl
        tva
        weightWithoutPackaging
    }
`;

export const ORDER_FRAGMENT = gql`
    fragment OrderFragment on Order {
        _id
        previous_orderId
        totemId
        totemNumber
        userId
        first_order
        date_delivery
        deliveryTimeWindows {
            start
            stop
        }
        state
        appreciation
        coworkers
        discount {
            amount
            description
            type
        }
        isPunctual
        frequency
        hasOnsiteSetup
        onsiteSetupInstructions
        weekday
        prices {
            discount
            price
            priceHT
            priceTTC
            priceDelivery
            tva
        }
        stateHistory {
            state
        }
        products {
            ...OrderProductFragment
        }
    }
    ${ORDER_PRODUCT_FRAGMENT}
`;
